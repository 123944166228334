@import '../node_modules/@clout-team/web-components/build/assets/styles/variables';
@import '../node_modules/@clout-team/web-components/build/assets/styles/main-colors';
@import '../node_modules/@clout-team/web-components/build/assets/styles/color-palette';
@import 'fonts';
*, *::before, *::after {
  box-sizing: border-box;
}

a {
  text-decoration: none;
  @include theme-aware('color', 'text-100');

  &:hover, &:active {
    @include theme-aware('color', 'text-100');
  }
}

html {
  height: 100%;
  min-height: 100%;
}
body {
  height: 100%;
  min-height: 100%;
  position: relative;
  font-family: "Lato", sans-serif;
  font-weight: 500;
  padding: 0;
  margin: 0;
  overflow: hidden;

  > iframe {
    display: none;
  }
}

button {
  font-family: "Lato", sans-serif;
}

p {
  margin: 0;
}

#root {
  /*height: 100%;
  min-height: 100%;
  position: relative;*/
  height: 100vh;
  min-height: 100%;
  position: absolute;
  width: 100%;
  top: 0;
  @include theme-aware('background-color', 'bg-body');
}


.scrollbar-default {
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    width: 2px;
  }

  &::-webkit-scrollbar-button {
    height: 0;
  }

  &::-webkit-scrollbar-track {
    opacity: 0;
  }

  &::-webkit-scrollbar-track-piece {
    background: none;
  }

  &::-webkit-scrollbar-thumb {
    height: 35px;
    background-color: transparent;
  }

  &::-webkit-scrollbar-corner, &::-webkit-resizer {
    background-color: var(--black);
  }

  &:hover::-webkit-scrollbar-thumb {
    background-color: var(--black-60);
  }

  overflow: hidden;
  overflow-y: auto;
}

input[type=search]::-ms-clear,
input[type=search]::-ms-reveal {
  display: none;
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}

.list-item__icon-main-wrapper--red {
  svg path {
    fill: $error !important;
  }
}

#context-root {
  & > * {
    border-radius: 8px;
  }
}

.is-mobile {
  #root {
    .page-layout-center {
      transform: translateX(0);
    }
  }

  .waiting-room-banner {
    width: calc(100vw - 32px);

    .waiting-room-footer {
      flex-direction: column;
    }
  }
}
